<template>
  <div class="main__product">
    <div class="product__title">
      <p>{{ item.attributes.name }}</p>
      <img src="@/assets/img/products/share.svg" alt="" />
    </div>
    <div class="product__content">
      <img class="product__content__pic" :src="image" alt="" />
      <div class="product__content__text">
        <span class="product-block__header"
          ><span class="product-block__stock"
            >-{{ item.attributes.discount }}%</span
          >до {{ item.attributes.action_end }}
        </span>
        <div class="product__content__price">
          <div class="prices">
            <p class="prices__old">{{ item.attributes.start_price }} тг</p>
            <p class="prices__new">{{ item.attributes.final_price }} тг</p>
          </div>
          <p class="prompt">Акция действует при наличии товара на полке.</p>
        </div>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="product__character__border"
        >
          <p>{{ item.name }}</p>
          <p>{{ item.desc }}</p>
        </div>
        <div class="desc">
          <p class="desc__title">Описание</p>
          <p class="desc__content">
            Прекрасный аперитив. Подавать к легким мясным закускам, салатам или
            клубничному пирогу. Идеален для азиатской кухни. Светло-розовый с
            блеском цвет. В букете доминируют тона персиков, клубники и оттенки
            цветов. Округлое во вкусе, с приятным освежающим послевкусием.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainProduct",
  props: {
    item: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
    },
  },
  data() {
    return {
      list: [
        {
          name: "Вид:",
          desc: "розовое",
        },
        {
          name: "Страна:",
          desc: "Крым",
        },
        {
          name: "Сахар:",
          desc: "полусладкое",
        },
        {
          name: "Виноградница:",
          desc: "Рислинг",
        },
        {
          name: "Бренд:",
          desc: "Villa Krim",
        },
        {
          name: "Крепоть:",
          desc: "9-13%",
        },
        {
          name: "Объем:",
          desc: "0,75 л.",
        },
      ],
    };
  },
  mounted() {
    console.log(this.item);
  },
};
</script>

<style lang="scss" scoped>
.product__title {
  font-size: 34px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 24px;
  margin-bottom: 48px;
}
.product__content {
  display: flex;
  justify-content: space-around;
  &__pic {
    max-width: 600px;
    object-fit: contain;
  }
  &__text {
    width: 32%;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .prices {
      &__old {
        color: #999999;
        font-size: 20px;
        text-decoration: line-through;
        // max-width: 600px;
      }
      &__new {
        font-size: 28px;
        font-weight: 900;
        color: #8344d6;
      }
    }
    .prompt {
      width: 48%;
      text-align: end;
    }
  }
}
.product__character__border {
  border-bottom: 1px solid #ececec;
  padding-bottom: 6px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.desc {
  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .product__content {
    &__pic {
      max-width: 300px;
    }
  }
}
@media screen and (max-width: 800px) {
  .product__title {
    flex-direction: column;
    img {
      width: 24px;
      height: 24px;
      // margin-top: 14px;
      // margin: 14px;
    }
  }
  .product__content {
    flex-direction: column;
    &__pic {
      // margin-bottom: 26px;
      margin: 0 auto 26px;
    }
    &__text {
      width: 100%;
    }
  }
}
</style>
