<template>
  <div v-if="product" class="full-product">
    <div class="container">
      <div class="full-product__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link to="catalog" class="crumbs-link page-catalog-link">
          {{ $t("discountCatalog") }}
        </locale-router-link>
        <a class="crumbs-link full-product-linmain-prk">{{
          product.attributes.name
        }}</a>
      </div>
      <main-product :item="product" :image="imageUrl" />
    </div>

    <!-- <shops-map
      :is-show-title="false"
      :is-show-shop-types="true"
      :isShowList="false"
      v-if="product.attributes.shops.data"
      :shops-list="product.attributes.shops.data"
    /> -->

    <weekly-products-list
      v-if="product"
      :exclude-product="product.id"
      :page-size="5"
    />
  </div>
</template>
<style lang="scss" scoped>
.product-block {
  width: auto !important;
}
</style>
<script>
import { mapState } from "vuex";
import { getProductById } from "@/api/products";
import MainProduct from "@/components/products/MainProduct";
export default {
  name: "FullProduct",
  components: {
    WeeklyProductsList: () =>
      import("@/components/products/WeeklyProductsList.vue"),
    // ShopsMap: () => import("@/components/maps/ShopsMap.vue"),
    MainProduct,
  },
  data() {
    return {
      product: null,
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    filteredShops() {
      return (
        this.product?.attributes?.shops?.data?.filter(
          (s) => s.attributes?.city?.data?.id === this.selectedCity?.id
        ) || []
      );
    },
    imageUrl() {
      if (this.product.attributes?.image?.data?.length) {
        return this.$helpers.getAbsolutePath(
          this.product.attributes?.image?.data[0]?.attributes?.url
        );
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    "$attrs.id": {
      handler() {
        getProductById(this.$attrs?.id).then((product) => {
          this.product = product;
          console.log(product);
        });
      },
    },
  },
  created() {
    getProductById(this.$attrs?.id).then((product) => {
      this.product = product;
    });
  },
  methods: {
    getDate(item) {
      const dateFromStr = new Date(item.attributes.action_end);
      return new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },
    getDateMob(item) {
      const dateFromStr = new Date(item.attributes.action_end);
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },
  },
};
</script>
